import React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image";
import { graphql, Link } from 'gatsby';
import {device} from '../utils';


const ListLinkItem = props => (
  <ListLink>
    <Link to={props.to}>{props.children}</Link>
  </ListLink>
)

export default ({menu}) => {
  return (
    <Nav className="navbar">
      <NavbarBrand>
        <Link to={`/`}>
          <Img fixed={menu.logo.childImageSharp.fixed} alt={'My Apron logo'} fadeIn={true} />
        </Link>
      </NavbarBrand>
      <NavLinks>
        <MenuOptions>
          <ListLinkItem to={`/`}>Home</ListLinkItem>
          <ListLinkItem to={`/blog`}>Blog</ListLinkItem>
          <ListLinkItem to={`/about`}>About</ListLinkItem>
          <ListLinkItem to={`/contact`}>Contact</ListLinkItem>
        </MenuOptions>
      </NavLinks>
    </Nav>
  )

};


// STYLING

const LogoContainer = styled.div``;
const LinksContainer = styled.div``;

const Nav = styled.nav `
  transition: all 1s ease;
  @media ${device.mobileS} {
    height: 150px;
    padding: 0;
    padding-bottom: 20px;
    text-align: center;
    height: auto;
  }
  @media ${device.tablet} {
    height: 80px;
    padding: 4px 15px;

  }
  background: #FFF;
  box-shadow: 0px 2px var(--red-shadow);
  .is-home & {
    position: absolute;
    left: 0;
    top: 0;
    width: 96%;
    z-index: 1;
    padding: 4px 2%;
  }
`;

const NavbarBrand = styled.div`
  @media ${device.mobileS} {
    display: block;
    text-align: center;
  }
  @media ${device.tablet} {
    display: inline-block;
    text-align: left;
    float: left;
  }
`;

const NavLinks = styled.div`
  @media ${device.mobileS} {
    height: auto;
    float: none;
  }
  @media ${device.tablet} {
    float: right;
    height: 100%;
  }
  display: flex;
  justify-items: center;
  position: relative;
`;

const MenuOptions = styled.ul`
  @media ${device.mobileS} {
    margin: 0 auto;
    text-align: center;
    float:left; 
    padding: 0;
  }
  @media ${device.tablet} {
    float: right;
    margin: auto 0;

  }
  
  list-style: none;
`;

const ListLink = styled.li`

  @media ${device.mobileS} {
    margin: 0;
  }
  @media ${device.tablet} {
    margin-right: 1em;
  }
  display: inline-block;
  
  a {
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    color: var(--red-light);
    font-size: 1.3em;
    padding: 8px;
    transition: all 300ms ease-in;
    
    &:hover, &:active {
      background-color: var(--red);
      color: #FFF;
    }
  }
`;

// QUERIES
export const query = graphql`
fragment NavbarFragment on Query {
  logo: file(relativePath: { eq: "Logo Rectangle.png"}) {
    childImageSharp {
      fixed(height: 80) {
        ...GatsbyImageSharpFixed
      }
    }
  } 
}
`;