import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Navbar from './NavBar';
import styled from 'styled-components';
import Footer from './Footer';
import { device } from '../utils';
import './layout.css';

const defaultAllMeta = {
  title: `My Apron`,
  metaDescription: `Your recipe management made easy with quick search, storage and sharing of your recipes. Organise your recipes into cookbooks, search for a recipe by name, category or ingredients, and share your delicious recipes with family and friends.`,
};
const Layout = ({title, metaDescription, children, isHome=false, pageImageURL=''}) => (
  
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        ...NavbarFragment
        ...FooterQuery
        site {
          siteMetadata {
            title
          }
        }
      }
  `}
      
    render={data => (
      <>
        <Helmet
          title={ title == `My Apron` ? (
            title  
          ) : (
            (title || defaultAllMeta.title) + ' | My Apron'
          )}
          meta={[
            { name: 'description', content: `${metaDescription || defaultAllMeta.metaDescription}` },
            // { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
          <link href="https://fonts.googleapis.com/css?family=Roboto:300,400" rel="stylesheet" defer />
          {pageImageURL && pageImageURL.length > 0 ? 
              <meta property="og:image:secure_url" content={`https://myapron.co${pageImageURL}`} />
            :
              ``
          }

        </Helmet>
          <Container className={isHome?`is-home` : ``}>
            <Navbar menu={data}></Navbar>
            {isHome ? 
            <div className={`content-wrapper`}>
              {children}
            </div>
            :
              <Content  className={`content-wrapper`}>
                {children}
              </Content>
            }
            <Footer data={data}></Footer>
          </Container>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  & > div.content-wrapper {
    padding-bottom: 250px;
  }
`;

const Content = styled.div`
  transition: all 1s ease;
  @media ${device.mobileS} {
    max-width: 94%;
    width: 100%;
  }
  @media ${device.tablet} {
    max-width: 75%;
    width: auto;
  }
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 250px;
`;
