import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';



export default ({data}) => {

  return (
    
    <Footer>
      <div>
          <p>My Apron</p>
          <p>Established 2018</p>
          <div className={`social`}>
            <a href="https://facebook.com/myapron.co" target="_blank" rel="noopener">
              <Img fixed={data.logoFB.childImageSharp.fixed} alt={'Facebook logo'} fadeIn={true} />
            </a>
            <a href="https://www.youtube.com/channel/UCN7Rt03VKJAAFRkaDqSgVkg" target="_blank" rel="noopener">
              <Img fixed={data.logoYT.childImageSharp.fixed} alt={'Youtube logo'} fadeIn={true} />
            </a>
            <a href="https://instagram.com/myapron.co" target="_blank" rel="noopener">
              <Img fixed={data.logoInsta.childImageSharp.fixed} alt={'Instagram logo'} fadeIn={true} />
            </a>
            <a href="https://pinterest.com/cd12f1ba9c1674149b05dcf16ce5c7" target="_blank" rel="noopener">
              <Img fixed={data.logoPT.childImageSharp.fixed} alt={'Pinterest logo'} fadeIn={true} />
            </a>
            <a href="https://twitter.com/myapronco" target="_blank" rel="noopener">
              <Img fixed={data.logoTW.childImageSharp.fixed} alt={'Twitter logo'} fadeIn={true} />
            </a>
          </div>
      <FooterLinks>
        <span><a href="/privacy-policy">Privacy</a></span>

      </FooterLinks>
      </div>

      
    </Footer>
  )

};

export const query = graphql`
  fragment FooterQuery on Query {
    logoFB: file(relativePath: { eq: "logo-facebook.png"}) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoInsta: file(relativePath: { eq: "logo-instagram.png"}) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoYT: file(relativePath: { eq: "logo-youtube.png"}) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoPT: file(relativePath: { eq: "logo-pinterest.png"}) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoTW: file(relativePath: { eq: "logo-twitter.png"}) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;


// STYLING
const Footer = styled.div`
  height: 200px;
  width: 100%;
  background: var(--red);
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  color: #FFF;
  display: flex;
  align-items: center;
  
  > div {
    margin: 0 auto;

    p {
      margin: 0;
    }
    div.social {
      padding-top: 10px;
      a .gatsby-image-wrapper {
        margin: 4px;
      }
    }
  }
`;

const FooterLinks = styled.div`
  width: 100%;
  text-align: center;
  margin: 10px 0; 
  span {
    margin: 0 4px;
    a {
      color: #FFF;
    }
  }
`;